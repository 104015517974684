import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AdminPanel = () => {
  const isOpen = localStorage.getItem("isOpen") === "true";
  const [isChecked, setIsChecked] = useState(isOpen);
  const { t, i18n } = useTranslation();

  return (
    <>
      <input
        type="checkbox"
        name="admin-nav"
        id="admin-nav"
        className="hidden"
        onChange={() => {
          setIsChecked(!isChecked);
          localStorage.setItem("isOpen", !isChecked);
        }}
      />
      <label
        htmlFor="admin-nav"
        className={`text-[2rem] cursor-pointer text-khamco-secondary transition-all z-[4]`}
      >
        {!isChecked && <i className="fas fa-bars"></i>}
        {isChecked && <i className="fas fa-times"></i>}
      </label>
      <ul
        className={`py-[2rem] px-0 list-none w-[80%] md:w-1/4 shadow-md shadow-[#666] rounded-[0.5rem] h-[100vh] overflow-y-scroll text-center transition-all z-[70] bg-[white] absolute mt-[1rem] ${
          !isChecked
            ? `${
                i18n.resolvedLanguage === "ar" || i18n.resolvedLanguage === "ur"
                  ? "translate-x-[125%]"
                  : "-translate-x-[125%]"
              }`
            : "-translate-x-0"
        }`}
      >
        <h2 className="no-underline mb-[2rem] text-2xl">
          {t("Administration")}
        </h2>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/dashboard"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname === "/admin/dashboard"
                ? "bg-[tomato]"
                : ""
            }`}
          >
            {t("Home")} <i className="fas fa-home"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/daily-activity"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname === "/admin/daily-activity"
                ? "bg-[tomato]"
                : ""
            }`}
          >
            Daily Activity <i className="fas fa-calendar-days"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/tailors"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname.includes("tailors") ? "bg-[tomato]" : ""
            }`}
          >
            Manage Employees <i className="fas fa-users"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/users/sales-performance"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname.includes("performance")
                ? "bg-[tomato]"
                : ""
            }`}
          >
            Monitor Salesman <i className="fas fa-business-time"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/customers"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname === "/admin/customers"
                ? "bg-[tomato]"
                : ""
            }`}
          >
            {t("Manage Customers")} <i className="fas fa-users"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/products/management"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li className="py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem]">
            Manage Inventory <i className="fas fa-receipt"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/invoices"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname === "/admin/invoices"
                ? "bg-[tomato]"
                : ""
            }`}
          >
            {t("Manage Invoices")} <i className="fas fa-receipt"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/orders"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname.includes("orders") ? "bg-[tomato]" : ""
            }`}
          >
            {t("Manage Orders")} <i className="fas fa-bookmark"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/expenses"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname.includes("expenses") ? "bg-[tomato]" : ""
            }`}
          >
            {t("Manage Expenses")} <i className="fas fa-business-time"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/revenue"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname.includes("revenue") ? "bg-[tomato]" : ""
            }`}
          >
            {t("Revenue Data")} <i className="fas fa-dollar-sign"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/customers-data"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname === "/admin/customers-data"
                ? "bg-[tomato]"
                : ""
            }`}
          >
            {t("Customer Data")} <i className="fas fa-user-group"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/service-times"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname === "/admin/service-times"
                ? "bg-[tomato]"
                : ""
            }`}
          >
            {t("Service Data")} <i className="fas fa-shirt"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/invoice-data"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname === "/admin/invoice-data"
                ? "bg-[tomato]"
                : ""
            }`}
          >
            {t("Invoice Data")} <i className="fas fa-receipt"></i>
          </li>
        </Link>
      </ul>
    </>
  );
};

export default AdminPanel;
