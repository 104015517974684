import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import AdminPanel from "../../components/AdminPanel";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../components/Footer";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import GridLoading from "../../components/GridLoading";
import AdminDataBoard from "../../components/AdminDataBoard";
import AdminExpenseBoard from "../../components/AdminExpenseBoard";
import { getApiUrl, getAuthKey } from "../../utils/apiUtils";

const AdminDashboard = () => {
  const apiUrl = getApiUrl();
  const authKey = getAuthKey();
  const [availableFabric, setAvailableFabric] = useState(0);
  const [averageServiceTime, setAverageServiceTime] = useState({});
  const [customers, setCustomers] = useState(0);
  const [invoiceData, setInvoiceData] = useState([]);
  const [dailyRevenue, setDailyRevenue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [display, setDisplay] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openBoard, setOpenBoard] = useState(false);
  const [expenseBoard, setExpenseBoard] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const chart = searchParams.get("chart");

  useEffect(() => {
    let isMounted = true;

    document.title = "Admin Dashboard - Khamco Shop";

    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/admin/dashboard`, {
          headers: { Authorization: `Bearer ${authKey}` },
        });
        if (isMounted) {
          setAvailableFabric(response.data.available_fabric);
          setAverageServiceTime(response.data.average_service_time);
          setCustomers(response.data.customers);
          setInvoiceData(response.data.invoice_data);
          setDailyRevenue(response.data.daily_revenue);
          setDisplay(true);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setDisplay(false);
          setIsLoading(true);
          if (error.response) {
            if (
              error.response.status === 403 ||
              error.response.status === 400
            ) {
              toast.warn(t("You are not authorized to access that resource."));
              navigate("/");
            } else {
              toast.error(error.response.data?.message || error.message);
            }
          } else {
            // Handle network errors or errors without a response object
            toast.error("An error occurred: " + error.message);
          }
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [apiUrl, authKey, navigate, t]);

  useEffect(() => {
    if (chart === "data-board") {
      setOpenBoard(true);
    } else if (chart === "expenses") {
      setExpenseBoard(true);
    }
  }, [chart]);

  return (
    <>
      <Navbar />
      <AdminDataBoard
        isOpen={openBoard}
        setIsOpen={setOpenBoard}
        setSearchParams={setSearchParams}
      />
      <AdminExpenseBoard
        isOpen={expenseBoard}
        setIsOpen={setExpenseBoard}
        setSearchParams={setSearchParams}
      />
      <main className="p-[1rem] md:p-[4rem]">
        <AdminPanel />
        <div className="flex flex-col md:flex-row gap-4 justify-around items-center my-8 w-full flex-wrap">
          {!isLoading && (
            <>
              <button
                className="text-[white] bg-[red] outline-none border-none py-[0.5rem] px-[1rem] rounded-md hover:opacity-90 cursor-pointer w-[80%] md:w-[10rem]"
                onClick={() => {
                  setExpenseBoard(true);
                  setSearchParams({ chart: "expenses" });
                }}
              >
                Expenses <i className="fas fa-minus"></i>
              </button>
              <button
                className="text-[white] bg-[green] outline-none border-none py-[0.5rem] px-[1rem] rounded-md hover:opacity-90 cursor-pointer w-[80%] md:w-[10rem]"
                onClick={() => {
                  setOpenBoard(true);
                  setSearchParams({ chart: "data-board" });
                }}
              >
                Data Board <i className="fas fa-chart-line"></i>
              </button>
            </>
          )}
        </div>
        <section className="admin-dashboard font-arial">
          <h1 className="text-center text-[2rem] font-minimoBold">
            {t("Admin Dashboard")}
          </h1>
          {isLoading && <GridLoading />}
          {display && (
            <div className="grid md:grid-cols-2 gap-[4rem] justify-center items-center w-[70vw] my-[2rem] m-auto">
              <div className="text-center shadow-lg shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem]">
                <h2 className="text-khamco-secondary text-2xl font-bold">
                  Daily Activity
                </h2>
                <h2 className="text-4xl font-semibold text-khamco-primary">
                  <i className="fas fa-calendar-days"></i>
                </h2>
                <Link
                  to="/admin/daily-activity"
                  className="py-[0.5rem] px-[1rem] outline-none border-none bg-[blue] text-white text-lg rounded-md cursor-pointer hover:opacity-90"
                >
                  {t("Details")}
                </Link>
              </div>
              {customers && (
                <div className="text-center shadow-lg shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem]">
                  <h2 className="text-khamco-secondary text-2xl font-bold">
                    {t("Total Customers")}
                  </h2>
                  <h2 className="text-xl font-semibold text-green-500">
                    {customers}
                  </h2>
                  <Link
                    to="/admin/customers-data"
                    className="py-[0.5rem] px-[1rem] outline-none border-none bg-[blue] text-white text-lg rounded-md cursor-pointer hover:opacity-90"
                  >
                    {t("Details")}
                  </Link>
                </div>
              )}
              <div className="text-center shadow-lg shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem]">
                <h2 className="text-khamco-secondary text-2xl font-bold">
                  Today's Revenue
                </h2>
                <h2 className="text-xl font-semibold text-green-500">
                  {new Intl.NumberFormat("kw-KW", {
                    style: "currency",
                    currency: "KWD",
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3,
                  }).format(dailyRevenue)}
                </h2>
                <Link
                  to="/admin/revenue"
                  className="py-[0.5rem] px-[1rem] outline-none border-none bg-[blue] text-white text-lg rounded-md cursor-pointer hover:opacity-90"
                >
                  {t("Details")}
                </Link>
              </div>
              <div className="text-center shadow-lg shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem]">
                <h2 className="text-khamco-secondary text-2xl font-bold">
                  {t("Total Fabric")}
                </h2>
                <h2 className="text-xl font-semibold text-green-500">
                  {availableFabric} meters
                </h2>
                <Link
                  to="/admin/fabrics"
                  className="py-[0.5rem] px-[1rem] outline-none border-none bg-[blue] text-white text-lg rounded-md cursor-pointer hover:opacity-90"
                >
                  {t("Details")}
                </Link>
              </div>
              <div className="text-center shadow-lg shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem]">
                <h2 className="text-khamco-secondary text-2xl font-bold">
                  {t("Average Service Time")}
                </h2>
                <h2 className="text-xl font-semibold text-green-500">{`${
                  averageServiceTime.days && averageServiceTime.days.toFixed(2)
                } day(s), ${
                  averageServiceTime.hours &&
                  averageServiceTime.hours.toFixed(2)
                } hours`}</h2>

                <Link
                  to="/admin/service-times"
                  className="py-[0.5rem] px-[1rem] outline-none border-none bg-[blue] text-white text-lg rounded-md cursor-pointer hover:opacity-90"
                >
                  {t("Details")}
                </Link>
              </div>
              <div className="text-center shadow-lg shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem]">
                <h2 className="text-khamco-secondary text-2xl font-bold">
                  {t("Invoice Data")}
                </h2>
                {invoiceData && (
                  <>
                    <h2 className="text-xl font-semibold text-green-500">{`${
                      invoiceData[0]?.total || 0
                    } created`}</h2>
                    <h2 className="text-xl font-semibold text-red-500">
                      {`${invoiceData[0]?.sum || 0} deleted`}
                    </h2>
                  </>
                )}
                <Link
                  to="/admin/invoice-data"
                  className="py-[0.5rem] px-[1rem] outline-none border-none bg-[blue] text-white text-lg rounded-md cursor-pointer hover:opacity-90"
                >
                  {t("Details")}
                </Link>
              </div>
            </div>
          )}
        </section>
      </main>
      <Footer />
    </>
  );
};

export default AdminDashboard;
