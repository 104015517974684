const BACKEND_PORT = process.env.REACT_APP_BACKEND_PORT || 5000;
const API_URL = process.env.REACT_APP_API_URL || '';

/**
 * 
 * @param {string} path 
 * @returns A localhost or production URL
 * @description The path to fetch (don't start path with "/")
 */
export const getApiUrl = (path = '') => window.location.origin.includes("localhost")
    ? `http://localhost:${BACKEND_PORT}${path}`
    : `${API_URL}${path}`;

export const getAuthKey = () => localStorage.getItem('jwt')

