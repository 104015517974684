import React, { useEffect, useRef, useState } from "react";
import { Chart, registerables } from "chart.js";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as htmlToImage from "html-to-image";
import GridLoading from "./GridLoading";
import { getApiUrl, getAuthKey } from "../utils/apiUtils";
Chart.register(...registerables);

const AdminDataBoard = ({ isOpen, setIsOpen, setSearchParams }) => {
  const apiUrl = getApiUrl();
  const authKey = getAuthKey();
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalDishdashas, setTotalDishdashas] = useState(0);
  const [totalPiecesSewn, setTotalPiecesSawn] = useState(0);
  const [newCustomers, setNewCustomers] = useState(0);
  const [oldCustomers, setOldCustomers] = useState(0);
  const [serviceSales, setServiceSales] = useState(0);
  const [fabricSales, setFabricSales] = useState(0);
  const [fabricProfit, setFabricProfit] = useState(0);
  const [top3Locations, setTop3Locations] = useState([]);
  const [averageWeeklySales, setAverageWeeklySales] = useState(0);
  const [customerAverageAmountSpent, setCustomerAverageAmountSpent] =
    useState(0);
  const [top3PayingCustomers, setTop3PayingCustomers] = useState([]);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [rent, setRent] = useState(0);
  const [productExpenses, setProductExpenses] = useState(0);
  const [salesManRewards, setSalesManRewards] = useState(0);
  const [totalOverhead, setTotalOverHead] = useState(0);
  const [topServiceItems, setTopServiceItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [pieChartValue, setPieChartValue] = useState(
    "Dishdashas Vs Sewn Pieces"
  );
  const [fabricData, setFabricData] = useState({});
  const pieChartRef = useRef(null);
  const pieChartRef2 = useRef(null);
  const pieChartRef3 = useRef(null);
  const pieChartRef4 = useRef(null);
  const areasRef = useRef(null);
  const topCustomersRef = useRef(null);
  const topServicesRef = useRef(null);
  const fabricSalesRef = useRef(null);
  const reportRef = useRef(null);
  const [linkLoading, setLinkLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/admin/data/board`, {
        headers: { Authorization: `Bearer ${authKey}` },
      });
      setTotalRevenue(response.data.totalRevenue);
      setTotalDishdashas(response.data.totalDishdashas);
      setTotalPiecesSawn(response.data.totalPiecesSewn);
      setNewCustomers(response.data.newCustomers);
      setOldCustomers(response.data.oldCustomers);
      setFabricSales(response.data.fabricTotalSales);
      setServiceSales(response.data.serviceTotalSales);
      setFabricProfit(response.data.fabricProfit);
      setTop3Locations(response.data.top3Locations);
      setAverageWeeklySales(response.data.averageWeeklySales);
      setCustomerAverageAmountSpent(response.data.customerAverageAmountSpent);
      setTop3PayingCustomers(response.data.top3PayingCustomers);
      setTotalDiscountAmount(response.data.totalDiscountAmount);
      setTotalRewards(response.data.totalRewards);
      setRent(response.data.rent);
      setProductExpenses(response.data.productExpenses);
      setSalesManRewards(response.data.salesManRewards);
      setTotalOverHead(response.data.totalOverhead);
      setTopServiceItems(response.data.top3Services);
      setFabricData(response.data.fabrics);
    } catch (error) {
      console.error("Error: ", error);
      if (error.response) {
        if (error.response.status === 403 || error.response.status === 400) {
          toast.warn("You are not authorized to access that resource.");
          navigate("/");
        } else {
          toast.error(error.response.data?.message || error.message);
        }
      } else {
        // Handle network errors or errors without a response object
        toast.error("An error occurred: " + error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [apiUrl, authKey, isOpen, navigate]);

  useEffect(() => {
    if (!pieChartRef.current) return;
    if (!pieChartRef2.current) return;
    if (!pieChartRef3.current) return;
    if (!pieChartRef4.current) return;
    if (!areasRef.current) return;
    if (!topCustomersRef.current) return;
    if (!topServicesRef.current) return;
    if (!fabricSalesRef.current) return;

    const pieCtx = pieChartRef?.current?.getContext("2d");
    const pieCtx2 = pieChartRef2?.current?.getContext("2d");
    const pieCtx3 = pieChartRef3?.current?.getContext("2d");
    const pieCtx4 = pieChartRef4?.current?.getContext("2d");
    const areasCtx = areasRef?.current?.getContext("2d");
    const topCustomersCtx = topCustomersRef?.current?.getContext("2d");
    const topServicesCtx = topServicesRef?.current?.getContext("2d");
    const fabricSalesCtx = fabricSalesRef?.current?.getContext("2d");

    const pieChart = new Chart(pieCtx, {
      type: "pie",
      data: {
        labels: ["Dishdashas", "Total Pieces Sewn"],
        datasets: [
          {
            label: "Sales",
            data: [totalDishdashas, totalPiecesSewn],
            backgroundColor: ["#ed1965", "blue"],
          },
        ],
      },
    });

    const pieChart2 = new Chart(pieCtx2, {
      type: "pie",
      data: {
        labels: ["New Customers", "Returning Customers"],
        datasets: [
          {
            label: "Sales",
            data: [newCustomers, oldCustomers],
            backgroundColor: ["#ed1965", "blue"],
          },
        ],
      },
    });

    const pieChart3 = new Chart(pieCtx3, {
      type: "pie",
      data: {
        labels: ["Fabric Sold", "Services"],
        datasets: [
          {
            label: "Total Sales (KWD)",
            data: [fabricSales, serviceSales],
            backgroundColor: ["#ed1965", "blue"],
          },
        ],
      },
    });

    const pieChart4 = new Chart(pieCtx4, {
      type: "pie",
      data: {
        labels: [
          "Total Rewards for Staff",
          "Salesman Rewards",
          "Fabric Costs",
          "Rent",
          "Total Overhead (Rent, Staff Rewards, Product Costs)",
        ],
        datasets: [
          {
            label: "(KWD)",
            data: [
              totalRewards,
              salesManRewards,
              productExpenses,
              rent,
              totalOverhead,
            ],
            backgroundColor: [
              `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
                Math.random() * 255
              })`,
              `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
                Math.random() * 255
              })`,
              `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
                Math.random() * 255
              })`,
              `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
                Math.random() * 255
              })`,
              `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
                Math.random() * 255
              })`,
            ],
          },
        ],
      },
    });

    const areasChart = new Chart(areasCtx, {
      type: "bar",
      data: {
        labels: top3Locations.map((item) => item._id),
        datasets: [
          {
            label: "Top 3 Customer Locations",
            data: top3Locations.map((item) => item.total),
            backgroundColor: ["#283688"],
          },
        ],
      },
      options: {
        scales: {
          y: {
            title: {
              display: true,
              text: "Count",
              color: "black",
              font: {
                size: 16,
              },
            },
            grid: {
              color: "#EC1664",
            },
            ticks: {
              color: "blue",
              font: {
                size: 14,
              },
            },
          },
          x: {
            title: {
              display: true,
              text: "Area Name",
              color: "black",
              font: {
                size: 16,
              },
            },
            grid: {
              color: "#EC1664",
            },
            ticks: {
              color: "blue",
              font: {
                size: 14,
              },
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            labels: {
              color: "black",
              font: {
                size: 20,
              },
            },
          },
        },
      },
    });

    const topCustomersChart = new Chart(topCustomersCtx, {
      type: "bar",
      data: {
        labels: top3PayingCustomers.map((customer) => customer.name),
        datasets: [
          {
            label: "Highest Paying Customers (KWD)",
            data: top3PayingCustomers.map((customer) => customer.amount),
            backgroundColor: ["green", "#283688", "#EC1664"],
          },
        ],
      },
      options: {
        scales: {
          y: {
            title: {
              display: true,
              text: "KWD",
              color: "black",
              font: {
                size: 16,
              },
            },
            grid: {
              color: "#EC1664",
            },
            ticks: {
              color: "blue",
              font: {
                size: 14,
              },
            },
          },
          x: {
            title: {
              display: true,
              text: "Area Name",
              color: "black",
              font: {
                size: 16,
              },
            },
            grid: {
              color: "#EC1664",
            },
            ticks: {
              color: "blue",
              font: {
                size: 14,
              },
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            labels: {
              color: "black",
              font: {
                size: 20,
              },
            },
          },
        },
      },
    });

    const topServicesChart = new Chart(topServicesCtx, {
      type: "bar",
      data: {
        labels: topServiceItems.map((item) => item.name),
        datasets: [
          {
            label: "Top 3 Most Common Service Items Purchased (KWD)",
            data: topServiceItems.map((item) => item.totalPurchased),
            backgroundColor: ["green", "#283688", "#EC1664"],
          },
        ],
      },
      options: {
        scales: {
          y: {
            title: {
              display: true,
              text: "KWD",
              color: "black",
              font: {
                size: 16,
              },
            },
            grid: {
              color: "#EC1664",
            },
            ticks: {
              color: "blue",
              font: {
                size: 14,
              },
            },
          },
          x: {
            title: {
              display: true,
              text: "Services",
              color: "black",
              font: {
                size: 16,
              },
            },
            grid: {
              color: "#EC1664",
            },
            ticks: {
              color: "blue",
              font: {
                size: 14,
              },
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            labels: {
              color: "black",
              font: {
                size: 20,
              },
            },
          },
        },
      },
    });

    const fabricsChart = new Chart(fabricSalesCtx, {
      type: "bar",
      data: {
        labels: Object.keys(fabricData),
        datasets: [
          {
            label: "Fabric Breakdown - Sales by Type (KWD)",
            data: Object.values(fabricData),
            backgroundColor: Object.values(fabricData).map(
              (_) =>
                `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
                  Math.random() * 255
                })`
            ),
            borderColor: "#EC1664",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            title: {
              display: true,
              text: "No. of Tailored Items",
              color: "black",
              font: {
                size: 16,
              },
            },
            grid: {
              color: "#EC1664",
            },
            ticks: {
              color: "blue",
              font: {
                size: 14,
              },
            },
          },
          x: {
            title: {
              display: true,
              text: "Clothing Items",
              color: "black",
              font: {
                size: 16,
              },
            },
            grid: {
              color: "#EC1664",
            },
            ticks: {
              color: "blue",
              font: {
                size: 14,
              },
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            labels: {
              color: "black",
              font: {
                size: 20,
              },
            },
          },
        },
      },
    });

    return () => {
      pieChart.destroy();
      pieChart2.destroy();
      pieChart3.destroy();
      pieChart4.destroy();
      areasChart.destroy();
      topCustomersChart.destroy();
      topServicesChart.destroy();
      fabricsChart.destroy();
    };
  }, [
    totalDishdashas,
    totalPiecesSewn,
    newCustomers,
    oldCustomers,
    fabricSales,
    serviceSales,
    top3Locations,
    top3PayingCustomers,
    totalRewards,
    salesManRewards,
    productExpenses,
    rent,
    totalOverhead,
    topServiceItems,
    fabricData,
  ]);

  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.key === "Escape") {
        setIsOpen(false);
        setSearchParams({ chart: "false" });
        if (reportRef.current) {
          reportRef.current.value = "";
        }
        if (reportRef.current) {
          reportRef.current.value = "";
        }
      }
    };

    // Add global event listeners
    document.addEventListener("keydown", handleKeydown);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [setIsOpen, setSearchParams]);

  const downloadReport = async () => {
    setLinkLoading(true);
    const element = reportRef.current;

    htmlToImage
      .toJpeg(element)
      .then((dataUrl) => {
        // Save or use the image URL
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `Admin Data.jpeg`;
        link.click();
      })
      .catch((error) => {
        console.error("Error generating image:", error);
        toast.error("Error generating image");
      })
      .finally(() => {
        setLinkLoading(false);
      });
  };

  return (
    <dialog
      className="fixed z-[50] bottom-0 left-2/4 -translate-x-2/4 w-[95%] rounded-t-[2rem] shadow-md shadow-[#444] overflow-y-auto overflow-x-hidden h-[95vh] p-[2rem] bg-[whitesmoke]"
      open={isOpen}
    >
      {loading && (
        <>
          <GridLoading />
        </>
      )}
      <button
        className="py-[0.5rem] px-[1rem] outline-none border-none bg-[red] text-white text-lg rounded-md cursor-pointer hover:opacity-90 absolute top-[5%] left-[5%]"
        type="button"
        onClick={() => {
          setIsOpen(false);
          setSearchParams({});
        }}
      >
        <i className="fas fa-times"></i>
      </button>
      {!loading && (
        <button
          className={`py-[0.5rem] px-[1rem] outline-none border-none ${
            linkLoading ? "bg-[#444]" : "bg-[darkorange]"
          } text-white text-lg rounded-md ${
            linkLoading ? "cursor-wait" : "cursor-pointer"
          } hover:opacity-90 absolute top-[5%] left-2/4 -translate-x-2/4`}
          onClick={downloadReport}
          disabled={linkLoading}
        >
          PDF{" "}
          <i
            className={`fas ${
              linkLoading ? "fa-rotate animate-spin" : "fa-download"
            }`}
          ></i>
        </button>
      )}
      <button
        className="bg-[gold] py-[0.5rem] px-[1rem] text-[1.5rem] rounded-full absolute top-[5%] right-[5%]"
        onClick={fetchData}
        disabled={loading}
        title="Refresh the report"
      >
        <i className={`fas fa-refresh ${loading ? "animate-spin" : ""}`}></i>
      </button>
      {!loading && (
        <div ref={reportRef}>
          <div className="flex flex-col md:flex-row mt-[20%] md:mt-[5%] bg-[white]">
            <div className="border-2 border-b-0 md:border-b-2 md:border-r-0 border-black w-full md:w-1/3 h-[10rem] text-center flex items-center justify-center text-4xl md:text-5xl font-bold relative">
              <span className="absolute top-2 left-2 text-sm font-normal">
                Total Revenue (KWD)
              </span>
              {new Intl.NumberFormat("kw-KW", {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              }).format(totalRevenue)}
            </div>
            <div className="border-2 border-b-0 md:border-b-2 md:border-r-0 border-black w-full md:w-1/3 h-[10rem] text-center flex items-center justify-center text-4xl md:text-5xl font-bold relative">
              <span className="absolute top-2 left-2 text-sm font-normal">
                Total Discount Amount given (KWD)
              </span>
              {new Intl.NumberFormat("kw-KW", {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              }).format(totalDiscountAmount)}
            </div>
            <div className="border-2 border-black w-full md:w-1/3 h-[10rem] text-center flex items-center justify-center text-4xl md:text-5xl font-bold relative">
              <span className="absolute top-2 left-2 text-sm font-normal">
                Average Spent per Customer (KWD)
              </span>
              {customerAverageAmountSpent > 100000
                ? new Intl.NumberFormat("kw-KW", {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3,
                  }).format(customerAverageAmountSpent)
                : "100K+"}
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-[1rem] justify-center">
            <div className="w-full md:w-2/4 flex flex-col gap-[1rem] bg-[white]">
              <select
                value={pieChartValue}
                onChange={(e) => {
                  setPieChartValue(e.target.value);
                }}
                className="mt-[2rem] w-full md:w-3/4 cursor-pointer select-none p-[0.5rem] outline-none border-2 border-[#444] transition-all duration-200 ease-in-out focus:border-khamco-primary"
              >
                <option value="Dishdashas Vs Sewn Pieces">
                  Dishdashas Vs Sewn Pieces
                </option>
                <option value="New Vs Returning Customers">
                  New Vs Returning Customers
                </option>
                <option value="Sales">Sales</option>
                <option value="Overhead Costs">Overhead Costs</option>
              </select>
              <div
                className={`border-2 border-black ${
                  pieChartValue === "Dishdashas Vs Sewn Pieces"
                    ? "block"
                    : "hidden"
                }`}
              >
                <canvas ref={pieChartRef} width="200" height="75"></canvas>
              </div>
              <div
                className={`border-2 border-black ${
                  pieChartValue === "New Vs Returning Customers"
                    ? "block"
                    : "hidden"
                }`}
              >
                <canvas ref={pieChartRef2} width="200" height="75"></canvas>
              </div>
              <div
                className={`border-2 border-black ${
                  pieChartValue === "Sales" ? "block" : "hidden"
                }`}
              >
                <canvas ref={pieChartRef3} width="200" height="75"></canvas>
                <div className="border-t-2 border-black w-full h-[8rem] text-center flex items-center justify-center text-4xl md:text-5xl font-bold relative text-green-600 bg-yellow-300">
                  <span className="absolute top-2 left-2 text-sm font-bold text-black">
                    Total Profit from Fabric Sales (KWD)
                  </span>
                  {new Intl.NumberFormat("kw-KW", {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3,
                  }).format(fabricProfit)}
                </div>
                <div className="border-t-2 border-black w-full h-[8rem] text-center flex items-center justify-center text-4xl md:text-5xl font-bold relative text-orange-600">
                  <span className="absolute top-2 left-2 text-sm font-bold text-black">
                    Average Weekly Sales (KWD)
                  </span>
                  {new Intl.NumberFormat("kw-KW", {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3,
                  }).format(averageWeeklySales)}
                </div>
              </div>
              <div
                className={`border-2 border-black ${
                  pieChartValue === "Overhead Costs" ? "block" : "hidden"
                }`}
              >
                <canvas ref={pieChartRef4} width="200" height="75"></canvas>
              </div>
            </div>
            <div className="w-full flex flex-col gap-[2rem] bg-[white] py-[2rem] px-[0.5rem]">
              <canvas width="200" height="75" ref={topCustomersRef}></canvas>
              <canvas width="200" height="75" ref={areasRef}></canvas>
            </div>
          </div>
          <div className="flex flex-col gap-[2rem] w-full bg-[white] py-[2rem] px-[0.5rem]">
            <canvas width="200" height="75" ref={topServicesRef}></canvas>
            <canvas width="200" height="75" ref={fabricSalesRef}></canvas>
          </div>
        </div>
      )}
    </dialog>
  );
};

export default AdminDataBoard;
