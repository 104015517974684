import React, { useEffect, useRef, useState } from "react";
import GridLoading from "./GridLoading";
import axios from "axios";
import { Chart, registerables } from "chart.js";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { Link, useNavigate } from "react-router-dom";
import { getApiUrl, getAuthKey } from "../utils/apiUtils";
Chart.register(...registerables);

const AdminExpenseBoard = ({ isOpen, setIsOpen, setSearchParams }) => {
  const apiUrl = getApiUrl();
  const authKey = getAuthKey();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const reportRef = useRef(null);
  const lineChartRef = useRef(null);
  const [sumOfInvoices, setSumOfInvoices] = useState({});
  const [contractsAndDeals, setContractsAndDeals] = useState([]);
  const [otherIncome, setOtherIncome] = useState([]);
  const [bankAndPaymentFees, setBankAndPaymentFees] = useState([]);
  const [contractorAndServices, setContractorAndServices] = useState([]);
  const [marketingFees, setMarketingFees] = useState([]);
  const [staffRewards, setStaffRewards] = useState([]);
  const [employeeSalaries, setEmployeeSalaries] = useState([]);
  const [shopRent, setShopRent] = useState([]);
  const [productCosts, setProductCosts] = useState([]);
  const [assetPurchases, setAssetPurchases] = useState([]);
  const [governmentFees, setGovernmentFees] = useState([]);
  const [shippingFees, setShippingFees] = useState([]);
  const [linkLoading, setLinkLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/admin/data/expenses`, {
        headers: { Authorization: `Bearer ${authKey}` },
      });
      setSumOfInvoices(response.data.sumOfInvoices);
      setContractsAndDeals(response.data.contractsAndDeals);
      setOtherIncome(response.data.otherIncome);
      setBankAndPaymentFees(response.data.bankAndPaymentFees);
      setContractorAndServices(response.data.contractorAndServices);
      setMarketingFees(response.data.marketingFees);
      setStaffRewards(response.data.staffRewards);
      setEmployeeSalaries(response.data.employeeSalaries);
      setShopRent(response.data.shopRent);
      setProductCosts(response.data.productCosts);
      setAssetPurchases(response.data.assetPurchases);
      setGovernmentFees(response.data.governmentFees);
      setShippingFees(response.data.shippingFees);
    } catch (error) {
      console.error("Error: ", error);
      if (error.response) {
        if (error.response.status === 403 || error.response.status === 400) {
          toast.warn("You are not authorized to access that resource.");
          navigate("/");
        } else {
          toast.error(error.response.data?.message || error.message);
        }
      } else {
        // Handle network errors or errors without a response object
        toast.error("An error occurred: " + error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!lineChartRef.current) return;

    const lineChartCtx = lineChartRef?.current?.getContext("2d");

    if (lineChartRef.current.chart) {
      lineChartRef.current.chart.destroy();
    }

    // Collect all unique months
    const allMonths = [
      ...new Set([
        ...Object.keys(sumOfInvoices),
        ...Object.keys(marketingFees),
        ...Object.keys(contractsAndDeals),
        ...Object.keys(otherIncome),
        ...Object.keys(bankAndPaymentFees),
        ...Object.keys(contractorAndServices),
        ...Object.keys(staffRewards), // Single value
        ...Object.keys(employeeSalaries), // Single value
        ...Object.keys(shopRent),
        ...Object.keys(productCosts), // Single value
        ...Object.keys(assetPurchases), // Single value
        ...Object.keys(governmentFees),
        ...Object.keys(shippingFees),
      ]),
    ];

    // Function to align data with months (fills missing months with 0)
    const mapDataToMonths = (data) =>
      allMonths.map((month) => (data[month] !== undefined ? data[month] : 0));

    const datasets = [
      {
        label: "Sum of Invoices",
        backgroundColor: "blue",
        borderColor: "blue",
        pointRadius: 5,
        data: mapDataToMonths(sumOfInvoices),
      },
      {
        label: "Contracts & Deals",
        backgroundColor: "orangered",
        borderColor: "orangered",
        pointRadius: 5,
        data: mapDataToMonths(contractsAndDeals),
      },
      {
        label: "Other Income",
        backgroundColor: "gold",
        borderColor: "gold",
        pointRadius: 5,
        data: mapDataToMonths(otherIncome),
      },
      {
        label: "Bank & Payment Fees",
        backgroundColor: "green",
        borderColor: "green",
        pointRadius: 5,
        data: mapDataToMonths(bankAndPaymentFees),
      },
      {
        label: "Contractor & Services",
        backgroundColor: "orange",
        borderColor: "orange",
        pointRadius: 5,
        data: mapDataToMonths(contractorAndServices),
      },
      {
        label: "Marketing Fees",
        backgroundColor: "lightblue",
        borderColor: "lightblue",
        pointRadius: 5,
        data: mapDataToMonths(marketingFees),
      },
      {
        label: "Staff Rewards",
        backgroundColor: "teal",
        borderColor: "teal",
        pointRadius: 5,
        data: allMonths.map(() => staffRewards || 0), // Convert single value to an array
      },
      {
        label: "Employee Salaries",
        borderColor: "tomato",
        backgroundColor: "tomato",
        pointRadius: 5,
        data: allMonths.map(() => employeeSalaries || 0),
      },
      {
        label: "Shop Rent",
        borderColor: "yellow",
        backgroundColor: "yellow",
        pointRadius: 5,
        data: mapDataToMonths(shopRent),
      },
      {
        label: "Product Costs",
        borderColor: "lime",
        backgroundColor: "lime",
        pointRadius: 5,
        data: mapDataToMonths(productCosts),
      },
      {
        label: "Asset Purchases",
        borderColor: "grey",
        backgroundColor: "grey",
        pointRadius: 5,
        data: mapDataToMonths(assetPurchases),
      },
      {
        label: "Government Fees",
        borderColor: "brown",
        backgroundColor: "brown",
        pointRadius: 5,
        data: mapDataToMonths(governmentFees),
      },
      {
        label: "Shipping Fees",
        borderColor: "navy",
        backgroundColor: "navy",
        pointRadius: 5,
        data: mapDataToMonths(shippingFees),
      },
    ];

    // Create Chart.js instance
    const lineChart = new Chart(lineChartCtx, {
      type: "line",
      data: {
        labels: allMonths,
        datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: true },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Months",
              color: "black",
              font: { size: 16 },
            },
          },
          y: {
            title: {
              display: true,
              text: "Expenses (KWD)",
              color: "black",
              font: { size: 16 },
            },
            beginAtZero: true,
          },
        },
      },
    });

    // Store chart instance for cleanup
    lineChartRef.current.chart = lineChart;

    return () => {
      lineChart.destroy();
    };
  }, [
    bankAndPaymentFees,
    contractorAndServices,
    contractsAndDeals,
    employeeSalaries,
    governmentFees,
    marketingFees,
    otherIncome,
    productCosts,
    assetPurchases,
    shippingFees,
    shopRent,
    staffRewards,
    sumOfInvoices,
  ]);

  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.key === "Escape") {
        setIsOpen(false);
        setSearchParams({ chart: "false" });
        if (reportRef.current) {
          reportRef.current.value = "";
        }
        if (reportRef.current) {
          reportRef.current.value = "";
        }
      }
    };

    // Add global event listeners
    document.addEventListener("keydown", handleKeydown);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [setIsOpen, setSearchParams]);

  const downloadReport = async () => {
    setLinkLoading(true);
    const element = reportRef.current;

    htmlToImage
      .toJpeg(element)
      .then((dataUrl) => {
        // Save or use the image URL
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `Admin Data.jpeg`;
        link.click();
      })
      .catch((error) => {
        console.error("Error generating image:", error);
        toast.error("Error generating image");
      })
      .finally(() => {
        setLinkLoading(false);
      });
  };

  return (
    <dialog
      className="fixed z-[50] bottom-0 left-2/4 -translate-x-2/4 w-[95%] rounded-t-[2rem] shadow-md shadow-[#444] overflow-y-auto overflow-x-hidden h-[95vh] p-[2rem] bg-[whitesmoke]"
      open={isOpen}
    >
      {loading && (
        <>
          <GridLoading />
        </>
      )}
      <button
        className="py-[0.5rem] px-[1rem] outline-none border-none bg-[red] text-white text-lg rounded-md cursor-pointer hover:opacity-90 absolute top-[5%] left-[5%]"
        type="button"
        onClick={() => {
          setIsOpen(false);
          setSearchParams({});
        }}
      >
        <i className="fas fa-times"></i>
      </button>
      {!loading && (
        <button
          className={`py-[0.5rem] px-[1rem] outline-none border-none ${
            linkLoading ? "bg-[#444]" : "bg-[darkorange]"
          } text-white text-lg rounded-md ${
            linkLoading ? "cursor-wait" : "cursor-pointer"
          } hover:opacity-90 absolute top-[5%] left-2/4 -translate-x-2/4`}
          onClick={downloadReport}
          disabled={linkLoading}
        >
          PDF{" "}
          <i
            className={`fas ${
              linkLoading ? "fa-rotate animate-spin" : "fa-download"
            }`}
          ></i>
        </button>
      )}
      <button
        className="bg-[gold] py-[0.5rem] px-[1rem] text-[1.5rem] rounded-full absolute top-[5%] right-[5%]"
        onClick={fetchData}
        disabled={loading}
        title="Refresh the report"
      >
        <i className={`fas fa-refresh ${loading ? "animate-spin" : ""}`}></i>
      </button>
      <section className="mt-[6rem] mb-[3rem]">
        <div className="flex flex-col gap-4 md:flex-row items-center justify-between">
          <Link
            to="/admin/expenses"
            className="bg-green-400 text-white py-[0.5rem] px-[1rem] text-[1.2rem] rounded-md hover:bg-green-500 w-full md:max-w-[16rem] text-center"
            target="_blank"
          >
            Manage Expenses <i className="fas fa-external-link"></i>
          </Link>
          <Link
            to="/admin/tailors"
            className="bg-blue-400 text-white py-[0.5rem] px-[1rem] text-[1.2rem] rounded-md hover:bg-blue-500 w-full md:max-w-[16rem] text-center"
            target="_blank"
          >
            Manage Employees <i className="fas fa-users"></i>
          </Link>
        </div>
      </section>
      <canvas ref={lineChartRef} width="400" height="500"></canvas>
    </dialog>
  );
};

export default AdminExpenseBoard;
